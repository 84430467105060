<template>
  <div id="p1s4c4">

    <div class="font-bold">
      <span class="flex text-xl">Successful Transactions:
        <span class="font-normal whitespace-pre selectable-text"> {{ P_calculatedSuccessfulTransactions }}</span>
      </span>
      <span class="flex text-xl">Successful Transactions Amount:
        <span class="font-normal whitespace-pre selectable-text"> ${{ formatAmount(P_calculatedSuccessfulTransactionAmount) }}</span>
      </span>
      <span class="flex text-xl">Pending Payment Amount:
        <span class="font-normal whitespace-pre selectable-text"> ${{ formatAmount(P_calculatedPendingPaymentAmount) }}</span>
      </span>
      <span class="flex text-xl">Pending Payment Links:
        <span class="font-normal whitespace-pre selectable-text"> {{ P_calculatedPendingPaymentLinks }}</span>
      </span>
      <span v-if="P_calculatedProcessedCredits !== 0" class="flex text-xl">Total Processed Credits:
        <span class="font-normal whitespace-pre selectable-text"> (${{ formatAmount(P_calculatedProcessedCredits) }})</span>
      </span>
      <span v-if="P_calculatedProcessedCredits === 0" class="flex text-xl">Total Processed Credits:
        <span class="font-normal whitespace-pre selectable-text"> ${{ formatAmount(P_calculatedProcessedCredits) }}</span>
      </span>
    </div>
  </div>
</template>

<script>
import _ from "lodash"

export default {
  name: "p1s4c4TransactionsData",

  props: [ 
    "P_calculatedSuccessfulTransactions",
    "P_calculatedSuccessfulTransactionAmount", 
    "P_calculatedPendingPaymentAmount", 
    "P_calculatedPendingPaymentLinks", 
    "P_calculatedProcessedCredits" 
  ],

  data() {
    return {};
  },

  mounted() {},

  methods: {
    formatAmount(amount) {
      return amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }
  },

  watch: {}
};
</script>